<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select
                  v-model="formInline.operationId"
                  filterable
                  size="15"
                  @change="merchantChange"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    v-for="value in merchantList"
                    :key="value.operationId"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <el-autocomplete
                  class="inline-input"
                  v-model="modelvalue"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入车场名称"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="创建人">
                <el-autocomplete
                  class="inline-input"
                  v-model="modelvalue2"
                  :fetch-suggestions="querySearchAsync2"
                  placeholder="请输入创建人"
                  value-key="userName"
                  :trigger-on-focus="false"
                  @select="handleSelect2"
                ></el-autocomplete>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                @click="$router.push('/staggerdManageAE')"
                v-if="$route.meta.authority.button.add"
                >{{ $t('button.addto') }}</el-button
              >
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
            </div>
          </div>
          <!-- <router-link :to="{ path: '/staggerdManageAE' }"> -->

          <!-- </router-link> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="80"
            class-name="small-padding fixed-width"
            v-if="$route.meta.authority.button.view | $route.meta.authority.button.edit"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" style="padding: 0" size="small"
                  >操作 <i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    command="view"
                    v-if="$route.meta.authority.button.view"
                    >查看</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    command="update"
                    v-if="$route.meta.authority.button.edit"
                    >编辑</AuthorityComponent
                  >
                  <!-- <AuthorityComponent ComponentName="el-dropdown-item"
                                      command='up'>上架
                  </AuthorityComponent>
                  <AuthorityComponent ComponentName="el-dropdown-item"
                                      command='down'>下架
                  </AuthorityComponent>-->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setIndex } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  name: "staggeredManage",
  data() {
    return {
      rowData: {},
      temParkData: {},
      // temParkData1: {},
      temParkData2: {},
      infoSize: 4,
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      modelvalue: "",
      // modelvalue1: "",
      merchantList: [],
      modelvalue2: "",
      rule: {},
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "creatorName",
          label: "创建人",
          width: "100",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Creation_time"),
          width: "",
        },
        {
          prop: "updatedTime",
          label: "修改时间",
          width: "",
        },
      ],
      tableData: [],
      page: 1,
      size: 15,
      formInline: {
        parkId: "",
        operationId: "",
        creatorId: "",
      },
    };
  },
  methods: {
    //   操作
    handleCommand(cmd, data) {
      if (cmd == "view") {
        this.$router.push({
          path: "./staggerdManageDetail",
          query: {
            staggeredParkId: data.staggeredParkId,
          },
        });
      } else if (cmd == "update") {
        this.$router.push({
          path: "./staggerdManageAE",
          query: {
            staggeredParkId: data.staggeredParkId,
          },
        });
      } else if (cmd == "up") {
        // 上架
        this.$axios
          .post("/acb/2.0/parkAppointmentGood/putOrOffShelf", {
            data: {
              appointmentGoodId: data.appointmentGoodId,
              goodState: 1,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              this.searParkRecordList();
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      } else if (cmd == "down") {
        // 下架
        // 上架
        this.$axios
          .post("/acb/2.0/parkAppointmentGood/putOrOffShelf", {
            data: {
              appointmentGoodId: data.appointmentGoodId,
              goodState: 2,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              this.searParkRecordList();
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searParkRecordList();
    },
    /* 停车场 */
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/parkNameList";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            parkTypes: "3,4",
            dataSource: "2",
            slaveRelations: "0,1",
            // slaveRelations: "0,1,2",
            operationId: this.formInline.operationId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.length ? res.value[0] : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 商户改变获取车场
    merchantChange(item) {
      // this.formInline.operationName = item.operationName;
      // this.formInline.operationId = item.operationId;
      // this.temParkData = { parkName: "", parkId: "" };
      // this.formInline.parkId = "";
      this.modelvalue = "";
    },
    // 获取商户列表
    getmerchant() {
      let opt = {
        method: "get",
        url: "/acb/2.0/operation/nameList",
      };
      this.$axios(opt).then((res) => {
        if (res.state == 0) {
          this.merchantList = res.value;
        }
      });
    },
    // /* 商户 */
    // querySearchAsync1(queryString, cb) {
    //   this.$emit("valueChange", "");
    //   queryString = queryString.replace(/\s/g, "");
    //   let url = "/acb/2.0/operation/nameList";
    //   this.$axios
    //     .get(url, {
    //       data: {
    //         page: 1,
    //         pageSize: 15,
    //         parkName: queryString,
    //         slaveRelations: "0,1,2",
    //       },
    //     })
    //     .then((res) => {
    //       if (res.state == 0) {
    //         this.temParkData1 = res.value.length
    //           ? res.value[0]
    //           : { operationName: "", operationId: "" };
    //         this.formInline.operationId = "";
    //         this.modelvalue = "";
    //         cb(res.value);
    //       } else {
    //         this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //           confirmButtonText: this.$t('pop_up.Determine'),
    //         });
    //       }
    //     });
    // },
    /* 创建人 */
    querySearchAsync2(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/bossManager/list";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            size: 100,
            bossManagerName: queryString,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData2 = res.value.list.length
              ? res.value.list[0]
              : { creator: "", creatorId: "" };
            this.formInline.creatorId = "";
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    // handleSelect1(item) {
    //   this.formInline.operationName = item.operationName;
    //   this.formInline.operationId = item.operationId;
    // },
    handleSelect2(item) {
      this.formInline.creatorId = item.userId;
      this.formInline.creator = item.userName;
    },
    searchData() {
      // console.log(this.formInline);
      if (!this.formInline.parkId) {
        this.modelvalue = this.temParkData.parkName;
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
      // if (!this.formInline.operationId) {
      //   this.modelvalue1 = this.temParkData1.operationName;
      //   this.formInline.operationId = this.temParkData1.operationId;
      //   this.temParkData1 = {};
      // }
      if (!this.formInline.creatorId) {
        this.modelvalue2 = this.temParkData2.creator;
        this.formInline.creatorId = this.temParkData2.creatorId;
        this.temParkData2 = {};
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/staggeredPark/pageList",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          parkId: this.formInline.parkId || "",
          operationId: this.formInline.operationId || "",
          creatorId: this.formInline.creatorId || "",
        },
      };
      this.$axios(opt).then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.tableData = setIndex(this.pageNum, res.value.list);
          this.total = res.value.total * 1 || 0;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 搜索
    searParkRecordList() {
      this.loading = true;
      if (this.formInline.parkId == "") {
        this.formInline.parkName = "";
      }
      if (this.formInline.operationId == "") {
        this.formInline.operationName = "";
      }
      if (this.formInline.creatorId == "") {
        this.formInline.creator = "";
      }
      this.searchData();
    },
  },
  mounted() {
    // console.log("当前权限为", this.$route.meta.authority.button);
    this.pageNum = 1;
    this.pageSize = 15;
    this.formInline = {
      parkId: "",
      operationId: "",
      creatorId: "",
    };
    // this.searchData();
    this.getmerchant();
  },
  activated() {
    this.searchData();
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
    // modelvalue1(newVal) {
    //   if (!newVal) {
    //     this.formInline.operationId = "";
    //     // this.temParkData1 = {};
    //   }
    // },
    modelvalue2(newVal) {
      if (!newVal) {
        this.formInline.creatorId = "";
        this.temParkData2 = {};
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
